import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import Seo from '../components/seo'

const NotFoundPage = ({ location }) => {
  return (
    <Layout location={location}>
      <main>
        <Link to="/blog/">
          <StaticImage
            style={{ width: '100%' }}
            src="../images/404.png"
            alt="Page not found"
          />
        </Link>
      </main>
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Page Not Found'
  const description = 'Page Not Found'

  return <Seo type="page" title={title} description={description} />
}

export default NotFoundPage
